import { css } from '@emotion/react'
import { MainContents } from '@/components/common/MainContents'
import { SideNav } from '@/components/common/SideNav'
import { MessageAlerts } from '@/components/common/MessageAlerts'
import { useDjangoCtx } from '@/hooks/useDjangoCtx'

interface Props {
  children: React.ReactNode
  mainContentsMaxWidth?: number
  mainContentsMinWidth?: number
}

export const Layout = ({
  children,
  mainContentsMaxWidth,
  mainContentsMinWidth,
}: Props) => {
  const styles = createStyles()

  const { alert_messages } = useDjangoCtx()

  return (
    <div css={styles.wrapper}>
      <SideNav />
      <MainContents
        contentsMaxWidth={mainContentsMaxWidth}
        contentsMinWidth={mainContentsMinWidth}
      >
        {alert_messages.length > 0 && (
          <MessageAlerts messages={alert_messages} />
        )}
        {children}
      </MainContents>
    </div>
  )
}

const createStyles = () => {
  return {
    wrapper: css`
      display: flex;
      height: 100vh;
      width: 100vw;
    `,
  }
}
