import {
  TurtleTheme,
  useTurtleTheme,
  theme as originalTheme,
} from '@dmm-com/turtle-components'
import { css } from '@emotion/react'

export const UnregisteredText = () => {
  const theme = useTurtleTheme()
  const styles = createStyles(theme)

  return <span css={styles.wrapper}>未登録</span>
}

const createStyles = (theme: TurtleTheme) => {
  return {
    wrapper: css`
      ${theme.turtle.typography.body4}

      padding: ${theme.turtle.spacing.small[1]} ${theme.turtle.spacing
        .small[3]};
      margin-left: ${theme.turtle.spacing.small[3]};
      background-color: ${originalTheme.color.light.red[400]};
      font-weight: ${originalTheme.fontWeights[600]};
      border-radius: ${theme.turtle.borderRadius.small};
      color: ${originalTheme.color.dark.gray[50]};
    `,
  }
}
