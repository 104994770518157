import { Logo } from '@/components/svg/Logo'
import {
  Button,
  TurtleTheme,
  theme as originalTheme,
  useTurtleTheme,
} from '@dmm-com/turtle-components'
import { css } from '@emotion/react'
import type { AccordionListItem, ListItem } from './type'

import { useDjangoCtx } from '@/hooks/useDjangoCtx'
import { navigate } from '@/library/url'
import { SideNavAccordionListItem } from './SideNavAccordionListItem'
import { SideNavListItem } from './SideNavListItem'
import { useMemo } from 'react'

const mainListItems: Array<ListItem> = [
  {
    title: '法人 サービスTOP',
    href: '/order/upload',
    leftIcon: 'home',
  },
  {
    title: '初めての方へ/規約',
    href: '/about',
    leftIcon: 'label_important',
  },
]

const loggedInUserListItems: Array<ListItem | AccordionListItem> = [
  {
    title: 'マイ3Dデータ一覧',
    href: '/order/models',
    leftIcon: 'view_list',
  },
  {
    title: 'バスケット',
    href: '/order/basket',
    leftIcon: 'shopping_basket',
  },
  {
    title: '注文履歴一覧',
    href: '/order/orders',
    leftIcon: 'history',
  },

  {
    title: '会員情報詳細',
    href: '/order/address',
    leftIcon: 'person',
  },

  {
    title: 'アカウント設定',
    leftIcon: 'settings',
    listItems: [
      {
        title: 'アカウント設定',
        href: '/auth/account',
      },
      {
        title: 'パスワード変更',
        href: '/auth/password',
      },
    ],
  },
]

const infoListItems: Array<ListItem | AccordionListItem> = [
  {
    title: 'お知らせ',
    href: 'https://make.dmm.com/print/news/',
    rightIcon: 'open_in_new',
  },
  {
    title: '素材・3Dデータについて',
    listItems: [
      {
        title: '素材一覧',
        href: 'https://make.dmm.com/print/material/',
        rightIcon: 'open_in_new',
      },
      {
        title: '3Dデータのルール',
        href: 'https://make.dmm.com/print/datarules/',
        rightIcon: 'open_in_new',
      },
    ],
  },
  {
    title: 'ヘルプセンター',
    href: 'https://make.dmm.com/print/inquiry/corp/',
    rightIcon: 'open_in_new',
  },
]

const isListItem = (item: ListItem | AccordionListItem): item is ListItem => {
  return Object.prototype.hasOwnProperty.call(item, 'href')
}

export const SideNav = () => {
  const { auth } = useDjangoCtx()

  const isLoggedIn = !!auth
  const needsAddressesInfo = isLoggedIn && !auth?.has_addresses
  const corporateName = auth?.long_corporate_name || ''
  const userName = useMemo(() => {
    if (!auth) return ''
    const { last_name, first_name } = auth
    return last_name + ' ' + first_name
  }, [auth])

  const theme = useTurtleTheme()
  const styles = createStyles(theme)

  const handleLogoClick = () => {
    navigate('/')
  }

  const complementedLoggedInUserListItems = useMemo(() => {
    return loggedInUserListItems.map((item) => {
      if (
        isListItem(item) &&
        item.href === '/order/address' &&
        needsAddressesInfo
      ) {
        return {
          ...item,
          warnText: '未登録',
        }
      } else return item
    })
  }, [needsAddressesInfo])

  return (
    <div css={styles.wrapper}>
      <div css={styles.logoWrapper}>
        <Logo
          width={120}
          height={40}
          color={'white'}
          onClick={handleLogoClick}
          aria-label="DMM.make 3D Print"
        />
      </div>
      <div css={styles.main}>
        <ul>
          {mainListItems.map((item, index) => {
            return (
              <SideNavListItem key={`${item.title}-${index}`} listItem={item} />
            )
          })}
          {isLoggedIn && (
            <>
              {complementedLoggedInUserListItems.map((item, index) => {
                if (Object.getOwnPropertyNames(item).includes('listItems')) {
                  return (
                    <SideNavAccordionListItem
                      key={`${item.title}-${index}`}
                      title={item.title}
                      leftIcon={item.leftIcon}
                      listItems={(item as AccordionListItem).listItems}
                    />
                  )
                }
                return (
                  <SideNavListItem
                    key={`${item.title}-${index}`}
                    listItem={item as ListItem}
                  />
                )
              })}
            </>
          )}
        </ul>
        <div css={styles.divider} />
        <ul>
          {infoListItems.map((item, index) => {
            if (Object.getOwnPropertyNames(item).includes('listItems')) {
              return (
                <SideNavAccordionListItem
                  key={`${item.title}-${index}`}
                  title={item.title}
                  leftIcon={item.leftIcon}
                  listItems={(item as AccordionListItem).listItems}
                />
              )
            }
            return (
              <SideNavListItem
                key={`${item.title}-${index}`}
                listItem={item as ListItem}
              />
            )
          })}
        </ul>
      </div>
      <div css={styles.info}>
        {isLoggedIn && (
          <>
            <SideNavListItem
              listItem={{
                title: 'ログアウト',
                leftIcon: 'logout',

                href: '/auth/logout/',
              }}
            />
            <div css={styles.textWrapper}>
              <p>{corporateName}</p>
              <p>{userName} 様</p>
            </div>
          </>
        )}

        {!isLoggedIn && (
          <>
            <Button
              css={[styles.button, styles.registerButton]}
              type="link"
              Link={({ children }) => (
                <a href={'/auth/register/'}>{children}</a>
              )}
              color="primary"
              variant="fill"
              label="新規会員登録"
            />
            <Button
              css={[styles.button, styles.loginButton]}
              type="link"
              Link={({ children }) => <a href={'/auth/login/'}>{children}</a>}
              color="primary"
              variant="outline"
              label="ログイン"
            />
          </>
        )}
      </div>
    </div>
  )
}

const createStyles = (theme: TurtleTheme) => {
  return {
    wrapper: css`
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 15rem;
      min-width: 15rem;
      background-color: ${originalTheme.color.light.gray[900]};
      padding: ${theme.turtle.spacing.small[4]};
    `,
    main: css`
      flex: 1;
      width: 100%;
      background-color: inherit;
      overflow-y: auto;
      /* IE, Edge 対応 */
      -ms-overflow-style: none;
      /* Firefox, Chrome 対応 */
      scrollbar-width: none;
      /* Safari 対応 */
      &::-webkit-scrollbar {
        display: none;
      }
    `,
    info: css`
      border-top: 1px solid ${originalTheme.color.light.gray[600]};
      background-color: inherit;
      padding-top: ${theme.turtle.spacing.small[4]};
    `,
    logoWrapper: css`
      padding: ${theme.turtle.spacing.small[3]};
      margin-bottom: ${theme.turtle.spacing.small[3]};
    `,
    listItem: css`
      margin-bottom: ${theme.turtle.spacing.small[1]};
      background-color: inherit;
    `,
    icon: css`
      margin-right: ${theme.turtle.spacing.small[3]};
    `,
    divider: css`
      height: 1px;
      background-color: ${originalTheme.color.light.gray[600]};
      width: 80%;
      margin: 10px auto;
    `,
    textWrapper: css`
      ${theme.turtle.typography.body3};
      color: ${originalTheme.color.light.gray[50]};
      padding: ${theme.turtle.spacing.small[3]};
    `,
    button: css`
      width: 100%;
    `,
    loginButton: css`
      outline: unset; /* turtleは背景色outlineが出るので無効に */
      & > a {
        border-color: ${originalTheme.color.light.blue[400]};
        color: ${originalTheme.color.light.gray[50]};
      }
    `,
    registerButton: css`
      & > a {
        background-color: ${originalTheme.color.light.blue[400]};
      }
      margin-bottom: ${theme.turtle.spacing.small[3]};
    `,
  }
}
