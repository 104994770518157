import { SvgProps } from '@/types/svg'

export const Logo = ({
  height = 24,
  width = 24,
  style,
  color,
  ...rest
}: SvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 120 40"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      {...rest}
    >
      <g clipPath="url(#clip0_1524_170625)">
        <g clipPath="url(#clip1_1524_170625)">
          <path d="M52.0432 0.120117H46.3366L44.2106 7.71233L41.8567 0.120117H36.5686L34.9731 8.40856L33.4895 0.120117H27.7787L25.6569 7.71233L23.303 0.120117H18.0191L16.8256 6.33645C15.9304 2.37872 12.3084 0.120117 6.85459 0.120117H0.194824V16.2494H8.26776C10.862 16.2494 13.763 15.4372 15.4953 13.2573L14.9234 16.2494H20.5678L21.7323 9.48191L23.7091 16.2494H28.1227L29.8053 9.51921L30.8289 16.2494H39.1132L40.2777 9.48191L42.2545 16.2494H46.6681L48.3507 9.51921L49.3784 16.2494H54.94L52.0432 0.120117ZM7.3063 10.6547V5.63193C8.5247 5.63193 10.3855 5.6858 10.3855 8.13504C10.3855 10.5843 8.5993 10.6547 7.3063 10.6547Z" />
          <path d="M68.1766 7.4554H68.2139C69.0551 6.3406 70.0912 5.86816 71.3842 5.86816C72.8305 5.86816 74.0862 6.45664 74.8115 7.78694C75.4953 6.53538 76.8463 5.86816 78.218 5.86816C80.7626 5.86816 81.6453 7.53 81.6453 9.84247V16.2411H78.0813V11.4463C78.0813 10.4102 78.1185 8.70695 76.6142 8.70695C74.9689 8.70695 74.9109 10.2527 74.9109 11.4463V16.2411H71.3469V11.4463C71.3469 10.3315 71.3469 8.62821 69.7597 8.62821C68.1724 8.62821 68.1724 10.3315 68.1724 11.4463V16.2411H64.6084V6.20385H68.1724V7.4554H68.1766Z" />
          <path d="M94.7161 16.2455H91.1521V15.1307H91.1148C90.489 16.1295 89.254 16.5771 88.0812 16.5771C85.1056 16.5771 83.0128 14.0905 83.0128 11.2145C83.0128 8.33837 85.0684 5.87256 88.0439 5.87256C89.2001 5.87256 90.4144 6.30356 91.1562 7.18213V6.2041H94.7202V16.2455H94.7161ZM86.6929 11.2352C86.6929 12.5075 87.5342 13.4855 88.9846 13.4855C90.4351 13.4855 91.2723 12.5075 91.2723 11.2352C91.2723 9.9629 90.431 8.96415 88.9846 8.96415C87.5383 8.96415 86.6929 10.0002 86.6929 11.2352Z" />
          <path d="M99.9378 9.82594L103.634 6.2039H108.508L103.477 10.8413L108.819 16.2412H103.829L99.9337 12.1509V16.2412H96.3696V0H99.9337V9.82594H99.9378Z" />
          <path d="M119.685 11.8193H112.188C112.188 13.2656 112.951 14.0489 114.418 14.0489C115.18 14.0489 115.727 13.7961 116.121 13.1288H119.544C118.976 15.4952 116.726 16.5727 114.434 16.5727C111.107 16.5727 108.62 14.6954 108.62 11.2308C108.62 7.76622 110.908 5.86816 114.177 5.86816C117.663 5.86816 119.677 8.01901 119.677 11.4463V11.8193H119.685ZM116.378 9.76373C116.2 8.80642 115.321 8.17649 114.364 8.17649C113.328 8.17649 112.466 8.72353 112.25 9.76373H116.378Z" />
          <path d="M62.1717 13.7213C62.1717 15.0516 61.0942 16.1332 59.7597 16.1332C58.4253 16.1332 57.3519 15.0557 57.3519 13.7213C57.3519 12.3868 58.4294 11.3135 59.7597 11.3135C61.09 11.3135 62.1717 12.391 62.1717 13.7213Z" />
          <path d="M0.559463 27.4185C0.584329 25.2345 1.46705 23.6348 2.88023 22.5697C4.2934 21.5295 6.21218 21.0239 8.29258 21.0239C13.6759 21.0239 15.4621 24.2233 15.4621 25.7939C15.4621 29.6853 13.0087 29.6853 13.0087 29.7641V29.818C13.0087 30.0293 16.0464 30.0293 16.0464 34.1611C16.0464 36.428 14.339 39.9962 8.26357 39.9962C-0.0787462 40.0003 -0.107756 34.1362 0.0290032 32.7479H5.09324C5.06837 33.7342 5.30459 34.4802 5.81433 34.9609C6.31993 35.5743 7.0949 35.7856 8.02735 35.7856C9.54827 35.7856 10.5056 35.2262 10.5056 33.7052C10.5056 32.0807 9.25403 31.7865 8.02735 31.7865C7.25238 31.7865 6.8794 31.8652 6.48155 31.9191V28.2929C6.8794 28.3178 7.30625 28.4006 8.02735 28.4006C9.14629 28.4006 10.5595 28.1603 10.5595 26.7222C10.5595 25.5494 9.22503 25.0977 8.1351 25.0977C6.69705 25.0977 5.52424 25.8727 5.57811 27.4185H0.559463Z" />
          <path d="M17.2483 20.7544H26.8712C33.216 20.7544 35.4538 25.4457 35.4538 30.2447C35.4538 36.0797 32.3623 39.7846 25.7274 39.7846H17.2524V20.7544H17.2483ZM23.1124 34.9069H25.4041C29.0552 34.9069 29.5898 31.9479 29.5898 30.1618C29.5898 28.9641 29.2168 25.6321 24.9773 25.6321H23.1124V34.9069Z" />
          <path d="M40.1409 20.7544H49.9502C55.1222 20.7544 56.9332 24.5381 56.9332 27.2567C56.9332 31.8692 53.8417 34.0283 49.4695 34.0283H46.0049V39.7846H40.1409V20.7544ZM46.0049 29.4946H48.2718C49.7928 29.4946 51.3883 29.3868 51.3883 27.468C51.3883 25.7357 50.0538 25.628 48.6158 25.628H46.0049V29.4946Z" />
          <path d="M57.6958 20.7544H68.8645C72.1964 20.7544 75.0725 22.5944 75.0725 26.1916C75.0725 28.1643 74.1649 30.2447 72.2213 30.9616C73.8209 31.575 74.8073 33.3321 75.0186 35.7067C75.0974 36.6392 75.1264 38.9061 75.6568 39.7846H69.7928C69.4985 38.8232 69.3949 37.841 69.312 36.8547C69.1504 35.0437 68.9929 33.1498 66.6722 33.1498H63.5557V39.7846H57.6917V20.7544H57.6958ZM63.5599 29.0718H66.6266C67.7207 29.0718 69.2126 28.8854 69.2126 27.1779C69.2126 25.9803 68.5454 25.284 66.3075 25.284H63.564V29.0677L63.5599 29.0718Z" />
          <path d="M76.8545 20.7544H82.7186V39.7846H76.8545V20.7544Z" />
          <path d="M84.9067 20.7544H90.9034L96.4484 30.9367H96.5023V20.7544H102.047V39.7846H96.3448L90.5097 29.3909H90.4558V39.7846H84.9109V20.7544H84.9067Z" />
          <path d="M108.686 25.6321H103.357V20.7544H119.88V25.6321H114.55V39.7846H108.686V25.6321Z" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1524_170625">
          <rect width="120" height="40" />
        </clipPath>
        <clipPath id="clip1_1524_170625">
          <rect width="119.88" height="40" />
        </clipPath>
      </defs>
    </svg>
  )
}
