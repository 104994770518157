export const getSearchParams = () => {
  const url = new URL(window.location.href)
  return url.searchParams
}
export const navigateWithNewParams = (newParams: URLSearchParams) => {
  const url = new URL(window.location.href)
  newParams.sort()
  url.search = newParams.toString()
  window.location.href = url.toString()
}

export const navigate = (path: string) => {
  const url = new URL(window.location.href)
  url.pathname = path
  window.location.href = url.toString()
}
