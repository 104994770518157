import { TurtleTheme, useTurtleTheme } from '@dmm-com/turtle-components'
import { css } from '@emotion/react'
import { Footer } from '@/components/common/Footer.tsx'

interface Props {
  children: React.ReactNode
  contentsMaxWidth?: number
  contentsMinWidth?: number
}

export const MainContents = ({
  children,
  contentsMaxWidth,
  contentsMinWidth,
}: Props) => {
  const theme = useTurtleTheme()
  const styles = createStyles(theme, contentsMaxWidth, contentsMinWidth)

  return (
    <div css={styles.wrapper}>
      <div css={styles.inner}>{children}</div>
      <Footer />
    </div>
  )
}

const createStyles = (
  theme: TurtleTheme,
  maxWidth?: number,
  minWidth?: number,
) => {
  const { spacing } = theme.turtle

  return {
    wrapper: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      background-color: ${theme.turtle.palette.backgroundColor
        .primaryBackground};
    `,
    inner: css`
      // padding分を含めず内部コンテンツのMaxを1000px, Min600pxに固定
      max-width: calc(${maxWidth ?? 1000}px + ${spacing.medium[1]} * 2);
      min-width: calc(${minWidth ?? 600}px + ${spacing.medium[1]} * 2);
      padding: ${spacing.medium[2]} ${spacing.medium[1]};
      width: 100%;
      color: ${theme.turtle.palette.onSurfaceColor.highEmphasis};
    `,
  }
}
