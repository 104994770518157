import {
  theme as originalTheme,
  TurtleTheme,
  useTurtleTheme,
} from '@dmm-com/turtle-components'
import { css } from '@emotion/react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { SideNavListItem } from './SideNavListItem'
import { AccordionListItem } from './type'

export const SideNavAccordionListItem = ({
  title,
  leftIcon,
  listItems,
}: AccordionListItem) => {
  const theme = useTurtleTheme()
  const styles = createStyles(theme)

  return (
    <Accordion elevation={0} disableGutters css={styles.accordion}>
      <AccordionSummary
        css={styles.accordionSummary}
        expandIcon={
          <span
            aria-label="expand icon"
            className="material-icons-round md-light md-16"
          >
            expand_more
          </span>
        }
        aria-controls="3d-print-material-content"
        id="3d-print-material-header"
      >
        <div css={styles.accordionSummaryContents}>
          {leftIcon && (
            <span css={styles.icon} className="material-icons-round md-light">
              {leftIcon}
            </span>
          )}
          {title}
        </div>
      </AccordionSummary>
      <AccordionDetails css={styles.AccordionDetails}>
        {listItems.map((item, index) => {
          return (
            <SideNavListItem
              key={`${item.title}-${index}`}
              listItem={item}
              css={styles.nestedListItem}
            />
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}

const createStyles = (theme: TurtleTheme) => {
  return {
    accordion: css`
      background-color: ${originalTheme.color.light.gray[900]};

      &::before {
        /* NOTE: 開閉時にシャドー的な役割の背景色がつくのでoverride */
        background-color: ${originalTheme.color.light.gray[900]};
      }
    `,
    AccordionDetails: css`
      background-color: rgba(75, 75, 75, 0.4);
      border-left: 1px solid ${theme.turtle.palette.dividerColor.divider};
      padding: 0;
      margin-left: ${theme.turtle.spacing.small[4]};
    `,
    accordionSummary: css`
      ${theme.turtle.typography.body3}
      color: ${originalTheme.color.light.gray[50]};
      padding: ${theme.turtle.spacing.small[3]};
      margin-bottom: ${theme.turtle.spacing.small[1]};

      & > .MuiAccordionSummary-content {
        margin: 0;
      }
    `,
    accordionSummaryContents: css`
      display: flex;
      align-items: center;
      justify-content: space-around;
    `,
    nestedListItem: css`
      background-color: inherit;
    `,
    icon: css`
      margin-right: ${theme.turtle.spacing.small[3]};
    `,
  }
}
