import {
  theme as originalTheme,
  TurtleTheme,
  useTurtleTheme,
} from '@dmm-com/turtle-components'
import { css } from '@emotion/react'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItem, { ListItemProps } from '@mui/material/ListItem'
import { ListItem as ListItemType } from './type'
import { UnregisteredText } from './UnregisteredText'

interface Props extends ListItemProps {
  listItem: ListItemType
}

export const SideNavListItem = ({
  listItem: { title, href, leftIcon, rightIcon, warnText },
  ...rest
}: Props) => {
  const theme = useTurtleTheme()
  const styles = createStyles(theme)
  const { fontSize, fontWeight, fontFamily } = theme.turtle.typography.body3

  const anchorProps = rightIcon === 'open_in_new' && {
    target: '_blank',
    rel: 'noopener noreferrer',
  }

  return (
    <ListItem css={styles.listItem} {...rest}>
      <ListItemButton
        css={styles.listItemButton}
        component="a"
        href={href}
        disableGutters
        {...anchorProps}
      >
        {leftIcon && (
          <ListItemIcon css={styles.leftIcon}>
            <span className="material-icons-round md-light">{leftIcon}</span>
          </ListItemIcon>
        )}
        <ListItemText
          primaryTypographyProps={{
            fontSize,
            fontWeight,
            fontFamily,
            color: originalTheme.color.light.gray[50],
          }}
        >
          {title}
          {warnText && <UnregisteredText />}
        </ListItemText>
        {rightIcon && (
          <ListItemIcon css={styles.rightIcon}>
            <span className="material-icons-round md-16 md-light">
              {rightIcon}
            </span>
          </ListItemIcon>
        )}
      </ListItemButton>
    </ListItem>
  )
}

const createStyles = (theme: TurtleTheme) => {
  return {
    listItem: css`
      padding: ${theme.turtle.spacing.small[3]};
      margin-bottom: ${theme.turtle.spacing.small[1]};
    `,
    listItemButton: css`
      padding: 0;
    `,
    leftIcon: css`
      margin-right: ${theme.turtle.spacing.small[3]};
      min-width: 24px;
    `,
    rightIcon: css`
      min-width: 24px;
    `,
  }
}
